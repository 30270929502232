
.nav {
    background-color: #f1f1f1;
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 9999;
}

.nav-row {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 24px 0;
}

.nav-list {
    list-style-type: none;
    margin: 0;
    padding: 12px;
}

.nav-list li {
    display: inline;
    padding: 40px;
}

.nav-list-mobile {
    position: absolute;
    background-color: #f1f1f1;
    display: none;
    list-style-type: none;
    top: 65px;
    width: 200px;
    height: 100vh;
    transition: all 0.5s ease;
}

.nav-list-mobile li {
    margin-top: 50px;
    padding: 10px 25px 10px 0;
}

.filler {
    width: 80%;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 786px) {

    .nav-list {
        display: none;
    }

    .nav-list-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

}