
.command {
    padding-top: 20px;
    margin-left: 5px;
    margin-bottom: 50px;
}

.command-base {
    display: flex;
    flex-wrap: nowrap;
}

.command-input {
    font-size: 18px;
    padding-left: 10px;
    background: inherit;
    font-family: inherit;
    color: #FFFFFF;
    outline: none;
    border: none;
    width: calc(100% - 500px);
}

@media screen and (max-width: 760px) {
    .command-input {
        width: calc(100% - 200px);
    }
}