
.dont-touch {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.dont-touch-message {
    font-size: 36px;
    font-weight: bold;
}

.dont-touch-button {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    border: none;
    color: white;
    font-weight: 900;
    font-size: 2.5rem;
    background: red;
    text-shadow: 0 3px 1px rgba(122,17,8,.8);
    box-shadow: 0 8px 0 rgb(183,9,0),
    0 15px 20px rgba(0,0,0,.35);
    text-transform: uppercase;
    transition: .4s all ease-in;
    outline: none;
    cursor: pointer;
    text-align: center;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}