
.quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(172, 230, 255, 0.26);
}
