.portfolio {
    padding-top: 150px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.portfolio-work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
}

@media screen and (max-width: 760px) {
    .portfolio {
        padding-top: 150px;
    }
}

@media screen and (max-width: 400px) {
    .portfolio-work {
        max-width: 300px;
    }
}