
.dialog {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}

.dialog-content {
    color: #FFFFFF;
    display: flex;
    background: #030303;
    border-radius: 5px;
    max-width: 100%;
    margin: 0 auto;
    padding: 15px;
    width: 560px;
    font-family: 'DotGothic16', sans-serif;
}

.dialog-actions {
    padding-left: 10px;
}

.close-dialog {
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background: inherit;
    color: #FFFFFF;
    outline: none;
    border: none;
}

.challenge-btn {
    cursor: pointer;
    text-decoration: underline;
    background: inherit;
    outline: none;
    border: none;
    color: #FFFFFF;
}

@media screen and (max-width: 560px) {
    .dialog-content {
        width: 100%;
        height: 100%;
        align-items: center;
        margin: 0 auto;
    }

    .close-dialog {
        right: 50px;
        top: 50px;
    }
}