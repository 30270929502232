@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');

.terminal {
    height: 100%;
    max-height: 100vh;
    width: 100%;
    position: fixed;
    background-color: #030303;
    font-family: 'DotGothic16', sans-serif;
    color: #FFFFFF;
    overflow-y: scroll;
}