
.endscreen-text {
    font-size: 24px;
    font-weight: bold;
}

.endscreen-buttons {
    display: flex;
    justify-content: center;
}

.endscreen-button {
    margin: 10px;
    border: 2px solid #56a5eb;
    border-radius: 30px;
    outline: none;
    height: 50px;
    padding: 15px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: all 0.5s ease;
}

.endscreen-button:hover {
    box-shadow: 4px 4px 10px #56a5eb;
    transform: translateY(-5px);
}