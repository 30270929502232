.home {
    padding-top: 200px;
    padding-bottom: 100px;
}

.home-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.home-content-text {
    width: 450px;
}

.home-img {
    margin-top: 20px;
    width: 450px;
    height: 390px;
    background: url('../media/images/header-resize.gif');
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;    
}

.home-img-mobile {
    display: none;
    width: 100%;
    height: 225px;
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    margin-bottom: 50px;
}

#moving-text {
    font-size: 36px;
}

#moving-text p {
    margin: 0;
}

@media (min-height: 850px) {
    .home {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 100vh;
    }
}

@media screen and (max-width: 760px) {
    .home {
        padding-top: 100px;
    }

    .home-img {
        display: none;
    }

    .home-img-mobile {
        display: block;
    }
}