
#coinBox{
    display: table;
    margin: 0 auto;
    height: 200px;
    width: 200px;
    cursor: pointer;
}

#coinBox:hover {
    transform: translateY(-20px);
}

.coinBoxFrame1{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            40px 0    0 #FFF000,
            60px 0    0 #FFF000,
            80px 0    0 #FFF000,
            100px 0    0 #FFF000,
            120px 0    0 #FFF000,
            140px 0    0 #FFF000,
                /*2nd line*/
            20px 20px 0 #FFF000,
            40px 20px 0 #FFD800,
            60px 20px 0 #FFD800,
            80px 20px 0 #FFD800,
            100px 20px 0 #FFD800,
            120px 20px 0 #FFD800,
            140px 20px 0 #FFD800,
            160px 20px 0 #FFD800,
                /*3rd line*/
            0    40px 0 #FFF000,
            20px 40px 0 #FFD800,
            40px 40px 0 #FFD800,
            60px 40px 0 #FFF,
            80px 40px 0 #FFAE00,
            100px 40px 0 #FFAE00,
            120px 40px 0 #FFAE00,
            140px 40px 0 #FFD800,
            160px 40px 0 #FFD800,
            180px 40px 0 #FFD800,
                /*4rd line*/
            0    60px 0 #FFD800,
            20px 60px 0 #FFD800,
            40px 60px 0 #FFF,
            60px 60px 0 #FFF,
            80px 60px 0 #FFF,
            100px 60px 0 #FFD800,
            120px 60px 0 #FFD800,
            140px 60px 0 #FFF000,
            160px 60px 0 #FFD800,
            180px 60px 0 #FFD800,
                /*5th line*/
            0    80px 0 #FFD800,
            20px 80px 0 #FFD800,
            40px 80px 0 #FFAE00,
            60px 80px 0 #FFF,
            80px 80px 0 #FFD800,
            100px 80px 0 #FFD800,
            120px 80px 0 #FFD800,
            140px 80px 0 #FFF000,
            160px 80px 0 #FFD800,
            180px 80px 0 #FFD800,
                /*6th line*/
            0    100px 0 #FFD800,
            20px 100px 0 #FFD800,
            40px 100px 0 #FFAE00,
            60px 100px 0 #FFD800,
            80px 100px 0 #FFD800,
            100px 100px 0 #FFD800,
            120px 100px 0 #FFD800,
            140px 100px 0 #FFF000,
            160px 100px 0 #FFD800,
            180px 100px 0 #FFD800,
                /*7th line*/
            0    120px 0 #FFD800,
            20px 120px 0 #FFD800,
            40px 120px 0 #FFAE00,
            60px 120px 0 #FFD800,
            80px 120px 0 #FFD800,
            100px 120px 0 #FFD800,
            120px 120px 0 #FFD800,
            140px 120px 0 #FFF000,
            160px 120px 0 #FFD800,
            180px 120px 0 #FFD800,
                /*8th line*/
            0    140px 0 #FFD800,
            20px 140px 0 #FFD800,
            40px 140px 0 #FFD800,
            60px 140px 0 #FFF000,
            80px 140px 0 #FFF000,
            100px 140px 0 #FFF000,
            120px 140px 0 #FFF000,
            140px 140px 0 #FFD800,
            160px 140px 0 #FFD800,
            180px 140px 0 #FFAE00,
                /*9th line*/

            20px 160px 0 #FFD800,
            40px 160px 0 #FFD800,
            60px 160px 0 #FFD800,
            80px 160px 0 #FFD800,
            100px 160px 0 #FFD800,
            120px 160px 0 #FFD800,
            140px 160px 0 #FFD800,
            160px 160px 0 #FFAE00,
                /*10th line*/

            40px 180px 0 #FFAE00,
            60px 180px 0 #FFAE00,
            80px 180px 0 #FFAE00,
            100px 180px 0 #FFAE00,
            120px 180px 0 #FFAE00,
            140px 180px 0 #FFAE00;
}

.coinBoxFrame2{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            50px 0    0 #FFF000,
            70px 0    0 #FFF000,
            90px 0    0 #FFF000,
            110px 0    0 #FFF000,
            130px 0    0 #FFF000,
                /*2nd line*/
            30px 20px 0 #FFF000,
            50px 20px 0 #FFD800,
            70px 20px 0 #FFD800,
            90px 20px 0 #FFD800,
            110px 20px 0 #FFD800,
            130px 20px 0 #FFD800,
            150px 20px 0 #FFD800,
                /*3rd line*/
            10px 40px 0 #FFF000,
            20px 40px 0 #FFD800,
            40px 40px 0 #FFD800,
            60px 40px 0 #FFD800,
            80px 40px 0 #FFAE00,
            90px 40px 0 #FFAE00,
            110px 40px 0 #FFAE00,
            130px 40px 0 #FFD800,
            150px 40px 0 #FFD800,
            170px 40px 0 #FFD800,
                /*4rd line*/
            10px    60px 0 #FFD800,
            20px 60px 0 #FFD800,
            40px 60px 0 #FFD800,
            60px 60px 0 #FFF,
            80px 60px 0 #FFD800,
            90px 60px 0 #FFD800,
            110px 60px 0 #FFD800,
            130px 60px 0 #FFF000,
            150px 60px 0 #FFD800,
            170px 60px 0 #FFD800,
                /*5th line*/
            10px    80px 0 #FFD800,
            20px 80px 0 #FFD800,
            40px 80px 0 #FFD800,
            60px 80px 0 #FFAE00,
            80px 80px 0 #FFD800,
            90px 80px 0 #FFD800,
            110px 80px 0 #FFD800,
            130px 80px 0 #FFF000,
            150px 80px 0 #FFD800,
            170px 80px 0 #FFD800,
                /*6th line*/
            10px    100px 0 #FFD800,
            20px 100px 0 #FFD800,
            40px 100px 0 #FFD800,
            60px 100px 0 #FFAE00,
            80px 100px 0 #FFD800,
            90px 100px 0 #FFD800,
            110px 100px 0 #FFD800,
            130px 100px 0 #FFF000,
            150px 100px 0 #FFD800,
            170px 100px 0 #FFD800,
                /*7th line*/
            10px    120px 0 #FFD800,
            20px 120px 0 #FFD800,
            40px 120px 0 #FFD800,
            60px 120px 0 #FFAE00,
            80px 120px 0 #FFD800,
            90px 120px 0 #FFD800,
            110px 120px 0 #FFD800,
            130px 120px 0 #FFF000,
            150px 120px 0 #FFD800,
            170px 120px 0 #FFD800,
                /*8th line*/
            10px 140px 0 #FFD800,
            30px 140px 0 #FFD800,
            50px 140px 0 #FFD800,
            60px 140px 0 #FFD800,
            80px 140px 0 #FFF000,
            100px 140px 0 #FFF000,
            110px 140px 0 #FFF000,
            130px 140px 0 #FFD800,
            150px 140px 0 #FFD800,
            170px 140px 0 #FFAE00,
                /*9th line*/
            30px 160px 0 #FFD800,
            50px 160px 0 #FFD800,
            70px 160px 0 #FFD800,
            90px 160px 0 #FFD800,
            110px 160px 0 #FFD800,
            130px 160px 0 #FFD800,
            150px 160px 0 #FFAE00,
                /*10th line*/
            50px 180px 0 #FFAE00,
            60px 180px 0 #FFAE00,
            80px 180px 0 #FFAE00,
            100px 180px 0 #FFAE00,
            120px 180px 0 #FFAE00,
            130px 180px 0 #FFAE00;
}

.coinBoxFrame3{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            60px 0    0 #FFF000,
            80px 0    0 #FFF000,
            100px 0    0 #FFF000,
            120px 0    0 #FFF000,
                /*2nd line*/
            40px 20px 0 #FFF000,
            60px 20px 0 #FFD800,
            80px 20px 0 #FFD800,
            100px 20px 0 #FFD800,
            120px 20px 0 #FFD800,
            140px 20px 0 #FFD800,
                /*3rd line*/
            20px 40px 0 #FFF000,
            40px 40px 0 #FFD800,
            60px 40px 0 #FFD800,
            80px 40px 0 #FFD800,
            100px 40px 0 #FFAE00,
            120px 40px 0 #FFD800,
            140px 40px 0 #FFD800,
            160px 40px 0 #FFD800,
                /*4rd line*/
            20px 60px 0 #FFD800,
            40px 60px 0 #FFD800,
            60px 60px 0 #FFD800,
            80px 60px 0 #FFAE00,
            100px 60px 0 #FFD800,
            120px 60px 0 #FFF000,
            140px 60px 0 #FFD800,
            160px 60px 0 #FFD800,
                /*5th line*/
            20px 80px 0 #FFD800,
            40px 80px 0 #FFD800,
            60px 80px 0 #FFD800,
            80px 80px 0 #FFAE00,
            100px 80px 0 #FFD800,
            120px 80px 0 #FFF000,
            140px 80px 0 #FFD800,
            160px 80px 0 #FFD800,
                /*6th line*/
            20px 100px 0 #FFD800,
            40px 100px 0 #FFD800,
            60px 100px 0 #FFD800,
            80px 100px 0 #FFAE00,
            100px 100px 0 #FFD800,
            120px 100px 0 #FFF000,
            140px 100px 0 #FFD800,
            160px 100px 0 #FFD800,
                /*7th line*/
            20px 120px 0 #FFD800,
            40px 120px 0 #FFD800,
            60px 120px 0 #FFD800,
            80px 120px 0 #FFAE00,
            100px 120px 0 #FFD800,
            120px 120px 0 #FFF000,
            140px 120px 0 #FFD800,
            160px 120px 0 #FFD800,
                /*8th line*/
            20px 140px 0 #FFD800,
            40px 140px 0 #FFD800,
            60px 140px 0 #FFD800,
            80px 140px 0 #FFD800,
            100px 140px 0 #FFF000,
            120px 140px 0 #FFD800,
            140px 140px 0 #FFD800,
            160px 140px 0 #FFAE00,
                /*9th line*/
            40px 160px 0 #FFD800,
            60px 160px 0 #FFD800,
            80px 160px 0 #FFD800,
            100px 160px 0 #FFD800,
            120px 160px 0 #FFD800,
            140px 160px 0 #FFAE00,
                /*10th line*/
            60px 180px 0 #FFAE00,
            80px 180px 0 #FFAE00,
            100px 180px 0 #FFAE00,
            120px 180px 0 #FFAE00;
}

.coinBoxFrame4{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            70px 0    0 #FFF000,
            90px 0    0 #FFF000,
            110px 0    0 #FFF000,
                /*2nd line*/
            50px 20px 0 #FFF000,
            70px 20px 0 #FFD800,
            90px 20px 0 #FFD800,
            110px 20px 0 #FFD800,
            130px 20px 0 #FFD800,
                /*3rd line*/
            50px 40px 0 #FFD800,
            70px 40px 0 #FFD800,
            90px 40px 0 #FFD800,
            110px 40px 0 #FFAE00,
            130px 40px 0 #FFD800,
                /*4rd line*/
            50px 60px 0 #FFD800,
            70px 60px 0 #FFD800,
            90px 60px 0 #FFD800,
            110px 60px 0 #FFAE00,
            130px 60px 0 #FFD800,
                /*5th line*/
            50px 80px 0 #FFD800,
            70px 80px 0 #FFD800,
            90px 80px 0 #FFD800,
            110px 80px 0 #FFAE00,
            130px 80px 0 #FFD800,
                /*6th line*/
            50px 100px 0 #FFD800,
            70px 100px 0 #FFD800,
            90px 100px 0 #FFD800,
            110px 100px 0 #FFAE00,
            130px 100px 0 #FFD800,
                /*7th line*/
            50px 120px 0 #FFD800,
            70px 120px 0 #FFD800,
            90px 120px 0 #FFD800,
            110px 120px 0 #FFAE00,
            130px 120px 0 #FFD800,
                /*8th line*/
            50px 140px 0 #FFD800,
            70px 140px 0 #FFD800,
            90px 140px 0 #FFD800,
            110px 140px 0 #FFAE00,
            130px 140px 0 #FFD800,
                /*9th line*/
            50px 160px 0 #FFD800,
            70px 160px 0 #FFD800,
            90px 160px 0 #FFD800,
            110px 160px 0 #FFD800,
            130px 160px 0 #FFD800,
                /*10th line*/
            70px 180px 0 #FFAE00,
            90px 180px 0 #FFAE00,
            110px 180px 0 #FFAE00;
}

.coinBoxFrame5{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            70px 0    0 #FFF000,
            90px 0    0 #FFF000,
            110px 0    0 #FFF000,
                /*2nd line*/
            70px 20px 0 #FFD800,
            90px 20px 0 #FFD800,
            110px 20px 0 #FFD800,
                /*3rd line*/
            70px 40px 0 #FFD800,
            90px 40px 0 #FFD800,
            110px 40px 0 #FFD800,
                /*4rd line*/
            70px 60px 0 #FFD800,
            90px 60px 0 #FFD800,
            110px 60px 0 #FFD800,
                /*5th line*/
            70px 80px 0 #FFD800,
            90px 80px 0 #FFD800,
            110px 80px 0 #FFD800,
                /*6th line*/
            70px 100px 0 #FFD800,
            90px 100px 0 #FFD800,
            110px 100px 0 #FFD800,
                /*7th line*/
            70px 120px 0 #FFD800,
            90px 120px 0 #FFD800,
            110px 120px 0 #FFD800,
                /*8th line*/
            70px 140px 0 #FFD800,
            90px 140px 0 #FFD800,
            110px 140px 0 #FFD800,
                /*9th line*/
            70px 160px 0 #FFD800,
            90px 160px 0 #FFD800,
            110px 160px 0 #FFD800,
                /*10th line*/
            70px 180px 0 #FFAE00,
            90px 180px 0 #FFAE00,
            110px 180px 0 #FFAE00;
}

.coinBoxFrame6{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            70px 0    0 #FFF000,
            90px 0    0 #FFF000,
            110px 0    0 #FFF000,
                /*2nd line*/
            50px 20px 0 #FFF000,
            70px 20px 0 #FFD800,
            90px 20px 0 #FFD800,
            110px 20px 0 #FFD800,
            130px 20px 0 #FFD800,
                /*3rd line*/
            50px 40px 0 #FFD800,
            70px 40px 0 #FFAE00,
            90px 40px 0 #FFD800,
            110px 40px 0 #FFD800,
            130px 40px 0 #FFD800,
                /*4rd line*/
            50px 60px 0 #FFD800,
            70px 60px 0 #FFAE00,
            90px 60px 0 #FFD800,
            110px 60px 0 #FFD800,
            130px 60px 0 #FFD800,
                /*5th line*/
            50px 80px 0 #FFD800,
            70px 80px 0 #FFAE00,
            90px 80px 0 #FFD800,
            110px 80px 0 #FFD800,
            130px 80px 0 #FFD800,
                /*6th line*/
            50px 100px 0 #FFD800,
            70px 100px 0 #FFAE00,
            90px 100px 0 #FFD800,
            110px 100px 0 #FFD800,
            130px 100px 0 #FFD800,
                /*7th line*/
            50px 120px 0 #FFD800,
            70px 120px 0 #FFAE00,
            90px 120px 0 #FFD800,
            110px 120px 0 #FFD800,
            130px 120px 0 #FFD800,
                /*8th line*/
            50px 140px 0 #FFD800,
            70px 140px 0 #FFAE00,
            90px 140px 0 #FFD800,
            110px 140px 0 #FFD800,
            130px 140px 0 #FFD800,
                /*9th line*/
            50px 160px 0 #FFD800,
            70px 160px 0 #FFD800,
            90px 160px 0 #FFD800,
            110px 160px 0 #FFD800,
            130px 160px 0 #FFD800,
                /*10th line*/
            70px 180px 0 #FFAE00,
            90px 180px 0 #FFAE00,
            110px 180px 0 #FFAE00;
}

.coinBoxFrame7{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            60px 0    0 #FFF000,
            80px 0    0 #FFF000,
            100px 0    0 #FFF000,
            120px 0    0 #FFF000,
                /*2nd line*/
            40px 20px 0 #FFF000,
            60px 20px 0 #FFD800,
            80px 20px 0 #FFD800,
            100px 20px 0 #FFD800,
            120px 20px 0 #FFD800,
            140px 20px 0 #FFD800,
                /*3rd line*/
            20px 40px 0 #FFF000,
            40px 40px 0 #FFD800,
            60px 40px 0 #FFD800,
            80px 40px 0 #FFAE00,
            100px 40px 0 #FFD800,
            120px 40px 0 #FFD800,
            140px 40px 0 #FFD800,
            160px 40px 0 #FFD800,
                /*4rd line*/
            20px 60px 0 #FFD800,
            40px 60px 0 #FFD800,
            60px 60px 0 #FFAE00,
            80px 60px 0 #FFD800,
            100px 60px 0 #FFF000,
            120px 60px 0 #FFD800,
            140px 60px 0 #FFD800,
            160px 60px 0 #FFD800,
                /*5th line*/
            20px 80px 0 #FFD800,
            40px 80px 0 #FFD800,
            60px 80px 0 #FFAE00,
            80px 80px 0 #FFD800,
            100px 80px 0 #FFF000,
            120px 80px 0 #FFD800,
            140px 80px 0 #FFD800,
            160px 80px 0 #FFD800,
                /*6th line*/
            20px 100px 0 #FFD800,
            40px 100px 0 #FFD800,
            60px 100px 0 #FFAE00,
            80px 100px 0 #FFD800,
            100px 100px 0 #FFF000,
            120px 100px 0 #FFD800,
            140px 100px 0 #FFD800,
            160px 100px 0 #FFD800,
                /*7th line*/
            20px 120px 0 #FFD800,
            40px 120px 0 #FFD800,
            60px 120px 0 #FFAE00,
            80px 120px 0 #FFD800,
            100px 120px 0 #FFF000,
            120px 120px 0 #FFD800,
            140px 120px 0 #FFD800,
            160px 120px 0 #FFD800,
                /*8th line*/
            20px 140px 0 #FFD800,
            40px 140px 0 #FFD800,
            60px 140px 0 #FFD800,
            80px 140px 0 #FFF000,
            100px 140px 0 #FFD800,
            120px 140px 0 #FFD800,
            140px 140px 0 #FFD800,
            160px 140px 0 #FFAE00,
                /*9th line*/
            40px 160px 0 #FFD800,
            60px 160px 0 #FFD800,
            80px 160px 0 #FFD800,
            100px 160px 0 #FFD800,
            120px 160px 0 #FFD800,
            140px 160px 0 #FFAE00,
                /*10th line*/
            60px 180px 0 #FFAE00,
            80px 180px 0 #FFAE00,
            100px 180px 0 #FFAE00,
            120px 180px 0 #FFAE00;
}

.coinBoxFrame8{
    background: rgba(0, 0, 0, 0);
    height: 20px;
    width: 20px;
    box-shadow:
        /*1st line*/
            50px 0    0 #FFF000,
            70px 0    0 #FFF000,
            90px 0    0 #FFF000,
            110px 0    0 #FFF000,
            130px 0    0 #FFF000,
                /*2nd line*/
            30px 20px 0 #FFF000,
            50px 20px 0 #FFD800,
            70px 20px 0 #FFD800,
            90px 20px 0 #FFD800,
            110px 20px 0 #FFD800,
            130px 20px 0 #FFD800,
            150px 20px 0 #FFD800,
                /*3rd line*/
            10px 40px 0 #FFF000,
            30px 40px 0 #FFD800,
            50px 40px 0 #FFD800,
            70px 40px 0 #FFAE00,
            80px 40px 0 #FFAE00,
            90px 40px 0 #FFAE00,
            100px 40px 0 #FFAE00,
            110px 40px 0 #FFD800,
            130px 40px 0 #FFD800,
            150px 40px 0 #FFD800,
            170px 40px 0 #FFD800,
                /*4rd line*/
            10px    60px 0 #FFD800,
            30px 60px 0 #FFD800,
            50px 60px 0 #FFF,
            70px 60px 0 #FFD800,
            90px 60px 0 #FFD800,
            100px 60px 0 #FFD800,
            120px 60px 0 #FFF000,
            140px 60px 0 #FFD800,
            160px 60px 0 #FFD800,
            170px 60px 0 #FFD800,
                /*5th line*/
            10px    80px 0 #FFD800,
            30px 80px 0 #FFD800,
            50px 80px 0 #FFAE00,
            70px 80px 0 #FFD800,
            90px 80px 0 #FFD800,
            100px 80px 0 #FFD800,
            120px 80px 0 #FFF000,
            140px 80px 0 #FFD800,
            160px 80px 0 #FFD800,
            170px 80px 0 #FFD800,
                /*6th line*/
            10px    100px 0 #FFD800,
            30px 100px 0 #FFD800,
            50px 100px 0 #FFAE00,
            70px 100px 0 #FFD800,
            90px 100px 0 #FFD800,
            100px 100px 0 #FFD800,
            120px 100px 0 #FFF000,
            140px 100px 0 #FFD800,
            160px 100px 0 #FFD800,
            170px 100px 0 #FFD800,
                /*7th line*/
            10px    120px 0 #FFD800,
            30px 120px 0 #FFD800,
            50px 120px 0 #FFAE00,
            70px 120px 0 #FFD800,
            90px 120px 0 #FFD800,
            100px 120px 0 #FFD800,
            120px 120px 0 #FFF000,
            140px 120px 0 #FFD800,
            160px 120px 0 #FFD800,
            170px 120px 0 #FFD800,
                /*8th line*/
            10px 140px 0 #FFD800,
            30px 140px 0 #FFD800,
            50px 140px 0 #FFD800,
            70px 140px 0 #FFF000,
            90px 140px 0 #FFF000,
            100px 140px 0 #FFF000,
            120px 140px 0 #FFD800,
            140px 140px 0 #FFD800,
            150px 140px 0 #FFD800,
            170px 140px 0 #FFAE00,
                /*9th line*/
            30px 160px 0 #FFD800,
            50px 160px 0 #FFD800,
            70px 160px 0 #FFD800,
            90px 160px 0 #FFD800,
            110px 160px 0 #FFD800,
            130px 160px 0 #FFD800,
            150px 160px 0 #FFAE00,
                /*10th line*/
            50px 180px 0 #FFAE00,
            60px 180px 0 #FFAE00,
            80px 180px 0 #FFAE00,
            100px 180px 0 #FFAE00,
            120px 180px 0 #FFAE00,
            130px 180px 0 #FFAE00;
}