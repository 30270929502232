
textarea {
    height: auto !important;
}

.code-edit-container {
    height: 100vh;
    background-color: #030303;
    display: block;
}

.base-code {
    margin: 0 0 0 20px;
    font-family: 'DotGothic16', sans-serif;
}

.exit-button {
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    background-color: #030303;
    color: #FFFFFF;
    font-family: 'DotGothic16', sans-serif;
    position: absolute;
    top: 5px;
    right: 15px;
}

.code-input,
.code-output {
    outline: none;
    width: 100%;
    height: auto;
    padding: 0 1rem 0;
    border: none;
    font-family: 'DotGothic16', sans-serif;
    background: transparent;
    white-space: pre-wrap;
    line-height: 1.5em;
    word-wrap: break-word;
    font-size: 1rem;
}

.code-input {
    margin: 10px 0 0;
    color: #FFFFFF;
    position: relative;
    left: 40px;
}

.code-output {
    pointer-events: none;
    z-index: 3;
    margin: 0;
    overflow-y: auto;
    outline: none;
    position: absolute;
    top: 34px;
    left: 24px;
}

code {
    position: relative;
    top: 0;
    left: 0;
    padding: 0 1rem 0;
    display: block;
    font-size: 0.8rem;
    font-family: 'DotGothic16', sans-serif;
}

.warning-message {
    display: none;
}

@media screen and (max-width: 450px) {
    .exit-button {
        position: relative;
        left: 10px;
    }
}

@media screen and (max-width: 300px) {

    .code-edit-container {
        filter: blur(5px);
    }

    .warning-message {
        display: block;
        color: #FFFFFF;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
}