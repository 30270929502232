
.questions-content {
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto;
}

.questions-question {
    font-size: 24px;
    font-weight: bold;
}

.questions-hud {
    display: flex;
    flex-wrap: wrap;
}

.progress-text {
    text-align: left !important;
    display: flex;
}

.progress-bar {
    width: 200px;
    border-radius: 30px;
    height: 20px;
    background-color: transparent;
    border: 1px solid #56a5eb;
}

.progress-bar-full {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 20px;
    background-color: #56a5eb;
}

.question-card {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 53px;
    border-radius: 30px;
    margin: 20px 0;
    transition: all 0.5s ease;
}

.question-card:hover {
    box-shadow: 4px 4px 10px #56a5eb;
    transform: translateY(-5px);
}

.question-card-number {
    text-align: center;
    color: #FFFFFF;
    background-color: #56a5eb;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 15px;
    min-width: 10px;
}

.question-card-content {
    padding-left: 10px;
    background-color: #FFFFFF;
    min-height: 53px;
    max-height: 53px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}