/* PrismJS 1.23.0
https://prismjs.com/download.html#themes=prism-dark&languages=clike+javascript */
/**
 * prism.js Dark theme for JavaScript, CSS and HTML
 * Based on the slides of the talk “/Reg(exp){2}lained/”
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
    color: white;
    background: none;
    text-shadow: 0 -.1em .2em black;
    font-family: 'DotGothic16', sans-serif;
    font-size: 1rem;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}

pre[class*="language-"],
:not(pre) > code[class*="language-"] {
    background: transparent;
    margin: 0;
}

/* Code blocks */
pre[class*="language-"] {
    overflow-y: auto;
    word-wrap: break-word;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #FFFFFF;
    text-decoration: none;
}

.token.punctuation {
    opacity: .7;
}

.token.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
    color: hsl(350, 40%, 70%);
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: hsl(75, 70%, 60%);
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
    color: hsl(40, 90%, 60%);
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: hsl(350, 40%, 70%);
}

.token.regex,
.token.important {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}

.token.deleted {
    color: red;
}