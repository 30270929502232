@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Dosis', sans-serif;
  background-color: #F9F9F9;
  overflow-x: hidden;
  font-size: 18px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #000
}

.container {
  width: 75%;
  margin: 0 auto;
}

.title-text {
  background-color: rgba(172, 230, 255, 0.26);
  font-size: 72px;
}

@media (min-width: 768px) {
  .container {
    width: 664px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 858px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1037.5px;
  }
}

@media (max-width: 500px) {
  .title-text {
    font-size: 64px;
  }
}